<template>
    <div style="background:#fff;">
        <a-layout-content style="padding:20px">
            <a-layout style="background:#fff;">
                <a-layout-header style="height:initial;background:#fff;padding: 0;">
                    <a-form layout="horizontal">
                        <a-form-item style="margin-bottom:10px;">
                            <a-form layout="inline">
                                <a-row>
                                    <a-col :span="20">
                                        <a-form-item>
                                            <label class="yj-partner-check_lab">领取类型：</label>
                                            <a-radio-group v-model="PromotionType" @change="ChangeTableData">
                                                <a-radio-button value="-1">全部</a-radio-button>
                                                <a-radio-button value="2">拉新</a-radio-button>
                                                <a-radio-button value="1">阅读</a-radio-button>
                                                <a-radio-button value="4">咨询</a-radio-button>
                                                <a-radio-button value="5">成交</a-radio-button>
                                            </a-radio-group>
                                        </a-form-item>
                                        <a-form-item>
                                            <!--<label class="yj-partner-check_lab">红包明细</label>-->

                                            <span style=" margin-left:30px">
                                                <label>已发金额：</label>
                                                <a-input-number style="margin:0 10px" v-model="ExpenditureMoney" :disabled="disabled" />
                                            </span>
                                            <span style=" margin-left:10px">
                                                <label>金额：</label>
                                                <a-input-number style="margin:0 10px" v-model="AccountMoney" :disabled="disabled" />
                                            </span>
                                            <!--<span style=" margin-left:10px">
                                                <label>累计领取：</label>
                                                <a-input-number style="margin:0 10px" v-model="Cumulative" :disabled="disabled" />个
                                            </span>-->
                                        </a-form-item>
                                    </a-col>
                                </a-row>

                            </a-form>
                        </a-form-item>
                    </a-form>

                </a-layout-header>
                <a-layout-content>
                    <div class="yj_list">
                        <a-table :columns="columns" bordered
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 @change="pagerChange"
                                 class="yj-table-img" style=" min-height:calc(100vh - 314px)">
                            <span slot="imgs" slot-scope="text">
                               <img class="imgPreview" :src="text" alt="头像" />
                            </span>
                            <span slot="IntegralType" slot-scope="text,record">
                                <span v-if="record.IntegralType==2">拉新</span>
                                <span v-else-if="record.IntegralType==1">阅读</span>
                                <span v-else-if="record.IntegralType==4">咨询</span>
                                <span v-else-if="record.IntegralType==5">成交</span>
                            </span>
                            <span slot="Summary" slot-scope="text,record">
                                <a v-if="record.IntegralType==5" v-on:click="OrderDetail(record.OrderID)">{{record.Summary}}</a>
                                <span v-else>{{record.Summary}}</span>
                            </span>
                        </a-table>


                    </div>
                </a-layout-content>
            </a-layout>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "头像",
                        dataIndex: "PartnerImg",
                        key: "ID",
                        align: "center",
                        scopedSlots: { customRender: "imgs" },
                    },
                    {
                        title: "团员",
                        dataIndex: "PartnerUserName",
                        ellipsis: true
                    },
                    {
                        title: "用户",
                        dataIndex: "UName",
                        ellipsis: true
                    },
                    {
                        title: "领取方式",
                        dataIndex: "IntegralType",
                        scopedSlots: { customRender: "IntegralType" },
                    },
                    {
                        title: "积分",
                        dataIndex: "IntegralNum",
                        ellipsis: true,
                        customRender: (text, item) => {
                            return "+" + item.IntegralNum;
                        },
                    },
                    {
                        title: "摘要",
                        dataIndex: "Summary",
                        width: 300,
                        ellipsis: true,
                        scopedSlots: { customRender: 'Summary' },
                    },
                    {
                        title: "领取时间",
                        width: 300,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    //{
                    //    title: "通过素材",
                    //    dataIndex: "MaterialName",
                    //    width: 200,
                    //    ellipsis: true
                    //},
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                PromotionType: "-1",
                ExpenditureMoney: "",  //支出金额
                AccountMoney: "",  //总金额
                ID: 0,
                disabled: true,
                Cumulative: "",
                BeforeLevel: "",
                NewLevel: "",
                RewardMoney: "",
                Rows: 10,
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            //订单详情
            OrderDetail(id){
                if(id==null)
                    return;
                var self = this;
                self.$router.push({
                    name: "Order_OrderDetail",
                    query: {
                        ID: id
                    }
                });
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            ChangeTableData(){
                console.log("gfuyds:"+this.PromotionType);
                this.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/PersonnelReceiveList",
                    data: {
                        PageIndex: self.pagination.current,
                        TeamID: self.ID,
                        ReceivingType:self.PromotionType,
                    },
                    OnSuccess: function (data) {
                        data.data.Data.Data.forEach(element => {
                            element.RewardMoney = parseFloat(element.RewardMoney).toFixed(2);
                        });
                        self.tableData = data.data.Data.Data;
                        self.ExpenditureMoney = data.data.ExpenditureMoney;
                        self.AccountMoney = data.data.AccountMoney;
                        self.pagination.total = data.data.Data.Total;
                        self.pagination.defaultPageSize = data.data.Data.RowCount;
                        self.pagination.current = data.data.Data.PageIndex;
                        console.log("fhsdh:",data.data.Data.Data);
                    }
                };
                http.Post(op);
            },

        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            var self = this;
            self.ID = self.$route.query.ID;
            self.getTableData();
        },

        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };


</script>
<style>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
</style>